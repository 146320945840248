import React, { Fragment } from "react";

import { t } from "i18next";

import { ModalV2, Button } from "@zolteam/react-ras-library";

import { cn } from "utils";

interface IConfirmModalCallback {
	onConfirm: () => Promise<any> | any;
	onCancel?: () => void;
	onClose: () => void;
	closeOnConfirm?: boolean;
}

interface IConfirmModalProps extends IConfirmModalCallback {
	className?: string;
	isOpen: boolean;
	title: string;
	text?: string;
	cancelText?: string;
	confirmText?: string;
	children?: React.ReactNode;
	size?: "s" | "m" | "l";
	buttons?: (
		| React.ReactNode
		| ((props: IConfirmModalCallback) => React.ReactNode)
		| "confirm"
		| "cancel"
	)[];
}

export const ConfirmModal: React.FC<IConfirmModalProps> = ({
	closeOnConfirm = true,
	...props
}: IConfirmModalProps) => {
	const { isOpen, onConfirm, onClose, onCancel } = props;
	const [isLoading, setIsLoading] = React.useState(false);

	const handleConfirm = () => {
		let res = onConfirm();
		setIsLoading(true);
		if (!res?.then) {
			res = new Promise((resolve) => resolve(res));
		}
		res.then(
			() => {
				setIsLoading(false);
				if (closeOnConfirm) onClose();
			},
			() => {
				setIsLoading(false);
			}
		);
	};

	const cancelButton = () => (
		<Button
			type="button"
			color="primary"
			outline
			className="cancelButton !text-primary-500 dark:!text-primary-300"
			onClick={onCancel ?? onClose}
			disabled={isLoading}
		>
			{props.cancelText ?? t("global.cancel")}
		</Button>
	);

	const confirmButton = () => (
		<Button
			type="button"
			color="primary"
			onClick={handleConfirm}
			isLoading={isLoading}
			className="confirmButton"
		>
			{props.confirmText ?? t("global.confirm")}
		</Button>
	);
	return (
		<ModalV2
			isDisplayed={isOpen}
			onClose={onClose}
			size={props.size ?? "m"}
			className={cn([
				"ConfirmModal !min-w-[90%] sm:!min-w-[70%] md:!min-w-0",
				"dark:[&>div:first-child]:bg-neutral-800 dark:[&>div:first-child]:text-white dark:bg-neutral-800 dark:text-white",
				props.className,
			])}
			title={props.title}
		>
			<div className="flex flex-col text-left gap-m">
				<div className="mb-5">{props.children ?? props.text}</div>
				<div className="flex flex-wrap-reverse justify-end gap-4 [&>*]:w-full sm:[&>*]:w-auto">
					{!!props.buttons?.length ? (
						props.buttons.map((button, key) => {
							return (
								<Fragment key={key}>
									{(button === "confirm" &&
										confirmButton()) ||
										(button === "cancel" &&
											cancelButton()) ||
										(typeof button === "function"
											? button({
													onConfirm: handleConfirm,
													onCancel: onCancel,
													onClose: onClose,
											  })
											: button)}
								</Fragment>
							);
						})
					) : (
						<>
							{cancelButton()}
							{confirmButton()}
						</>
					)}
				</div>
			</div>
		</ModalV2>
	);
};
