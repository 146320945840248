import React, { useEffect, PropsWithChildren, useLayoutEffect } from "react";

import { t } from "i18next";
import { isEqual, omit } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminMenuRoutes, UserMenuRoutes } from "router/Routes";

import AgencyService from "services/AgencyService";
import SessionService from "services/SessionService";
import UserAdminService from "services/UserAdminService";

import useAppContext from "store/useAppContext";

import { adminLayoutAnimations } from "./adminLayoutAnimations";
import { IAgency } from "@components/types";
import { Picto, Button } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ChangeLogToast, Header, Menu, NavBar } from "components/organisms";

import { cn } from "utils";

const MOBILE_WIDTH = 640;

export interface IAdminLayoutProps extends PropsWithChildren {
	className?: string;
	childClassName?: string;
	scrollable?: boolean;
}

const AdminLayout: React.FC<IAdminLayoutProps> = ({
	children,
	className,
	childClassName,
	scrollable = true,
}) => {
	const [isMobile, setIsMobile] = React.useState(
		window.innerWidth < MOBILE_WIDTH
	);
	const { isAdmin, isLoggedAs, appState, dispatch } = useAppContext();
	const location = useLocation();
	const previousLocationRef = React.useRef(location); // needed to avoid scroll to top on tab refocus
	const navigate = useNavigate();
	const routes = isAdmin() && !isLoggedAs ? AdminMenuRoutes : UserMenuRoutes;
	const isSwitching = SessionService.isSwitchingUser();

	const handleResize = () => {
		setIsMobile(window.innerWidth < MOBILE_WIDTH);
	};

	const handleLogoutConnectAs = () => {
		const newPayload = {
			...{
				...appState.auth,
				...appState.auth.oldState,
			},
		};

		delete newPayload.oldState;

		dispatch({
			type: "CLEAR_AUTH_CONNECT_AS",
			payload: newPayload,
		});
		navigate(
			isLoggedAs.id
				? `/admin-users/profile/${isLoggedAs.id}`
				: "/admin-users"
		);
	};

	useEffect(() => {
		if (previousLocationRef.current?.pathname === location.pathname) return;

		// scroll to top on route change
		document.getElementById("AdminLayout")?.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		document.getElementById("AdminLayoutBody")?.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		previousLocationRef.current = location;
	}, [location]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useLayoutEffect(() => {
		adminLayoutAnimations();
	}, []);

	useEffect(() => {
		const defineActiveAgencies = (agencies: IAgency[]) => {
			// récupère les agences présentes dans le state sans le champ active
			const appStateAgencies = appState.auth.agencies
				? appState.auth.agencies.reduce(
						(acc: IAgency[], agency: IAgency) => {
							acc.push(omit(agency, ["active"]));
							return acc;
						},
						[]
				  )
				: [];

			// si les agence ne sont pas les mêmes que celles récupérées, on les met à jour
			if (isEqual(agencies, appStateAgencies)) return;

			// crée une map des agences actives
			const activeStateMap = new Map(
				appState.auth.agencies.map((agency: IAgency) => [
					agency.id,
					agency.active,
				])
			);

			// met à jour les agences récupérées avec le champ active, par défaut à true si l'agence n'était pas présente dans le state
			const activeAgencies = agencies.map((agency: IAgency) => ({
				...agency,
				active: activeStateMap.has(agency.id)
					? activeStateMap.get(agency.id)
					: true,
			}));

			// met à jour le state
			dispatch({
				type: "SET_USER_AGENCIES",
				payload: activeAgencies,
			});
		};

		UserAdminService.getUserSite().then((res) => {
			if (res && res.data) {
				dispatch({ type: "SET_USER_SITES", payload: res.data });
			}
		});

		AgencyService.getAgencies().then((res) => {
			if (!res) return;
			defineActiveAgencies(res);
		});
	}, [dispatch, appState.auth.agencies]);

	return (
		<div
			id="AdminLayout"
			className={cn([
				`relative flex flex-col h-full sm:flex-row bg-neutral-100 dark:bg-neutral-900 ${
					scrollable ? "overflow-auto" : "overflow-hidden"
				}`,
				className,
			])}
		>
			{!isMobile && (
				<div className="pt-[8rem] w-[6rem] lg:w-[8rem] sticky -top-[8rem] z-0 duration-300">
					<Picto icon="logo" className="absolute logo top-5 left-5" />
					<Menu className="top-0 menu" items={routes} />
					{!isAdmin() && !isLoggedAs && (
						<div className="absolute flex justify-center w-full bottom-8">
							<Button
								color="primary"
								className="z-50 !p-2 !rounded-full"
								type="button"
								onClick={() => navigate("/contact")}
							>
								<Picto
									icon="contact"
									style={{ height: "2rem", margin: 0 }}
								/>
							</Button>
						</div>
					)}
				</div>
			)}
			<div
				id="AdminLayoutBody"
				className="flex-1 w-full sm:w-[calc(100%-8rem)] z-[1] overflow-x-hidden sm:overflow-x-visible"
			>
				{isLoggedAs && (
					<div className="sticky top-0 z-50 w-full py-1 text-sm text-center bg-white sm:fixed dark:bg-neutral-950 dark:text-white rounded-b-xl bg-opacity-70">
						<span>
							{t("adminUsers.connectedAs", {
								user: isLoggedAs.email,
							})}
						</span>
						<Button
							type="button"
							color="transparent"
							className="ml-2 font-bold !text-primary-500"
							onClick={handleLogoutConnectAs}
						>
							{t("auth.logout")}
						</Button>
					</div>
				)}
				<Header
					className={cn([
						"sm:h-[72px] header z-[2] relative",
						isLoggedAs && "sm:mt-[28px]",
					])}
					id={"AdminLayout__header"}
				/>
				<div
					className={cn([
						"w-full z-[3] min-h-[calc(100%-72px)] relative bg-neutral-50 dark:bg-neutral-800 shadow-2xl rounded-t-[3rem] col sm:rounded-tr-none duration-300",
						childClassName,
					])}
				>
					{(isSwitching && (
						<Spinner
							className="my-8"
							text="Changement d'utilisateur"
						/>
					)) ||
						children}
				</div>
			</div>
			{isMobile && <NavBar items={routes} className="z-10" />}
			<ChangeLogToast />
		</div>
	);
};

export default AdminLayout;
