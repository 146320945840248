import i18n from "i18n/i18n";

const { t } = i18n;

export const TIMESHEET_STATUS_WAITING = "waiting";
export const TIMESHEET_STATUS_SENT = "sent";
export const TIMESHEET_STATUS_VALIDATED = "validated";
export const TIMESHEET_STATUS_DONE = "done";

export const TIMESHEETS_STATUS = [
	{
		name: t("global.all"),
		value: undefined,
	},
	{
		name: t("timesheets.status.waiting"),
		value: TIMESHEET_STATUS_WAITING,
	},
	{
		name: t("timesheets.status.sent"),
		value: TIMESHEET_STATUS_SENT,
	},
	{
		name: t("timesheets.status.validated"),
		value: TIMESHEET_STATUS_VALIDATED,
	},
	{
		name: t("timesheets.status.done"),
		value: TIMESHEET_STATUS_DONE,
	},
];

export const TIMESHEET_ENTRY_MODE_STORAGE_KEY = "timesheetEntryMode";
