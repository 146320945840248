import React from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import { SelectLabel, Picto, Button } from "@zolteam/react-ras-library";
import {
	IFilterStatus,
	IActionsListItem,
	IListHeaderProps,
} from "components/types";

import { cn } from "utils";

import "./ListHeader.scss";

export const ListHeader: React.FC<IListHeaderProps> = ({
	selectable,
	selected,
	onSelected,
	columns,
	sortBy,
	actions,
	disabled,
	handleFilter,
	items,
}) => {
	const SortBy = columns
		? columns.find((a) => a.value === sortBy || a.sortKey === sortBy) ??
		  columns[0]
		: null;

	const selectableItems = items?.filter((a) => {
		return typeof selectable === "function" ? selectable(a) : true;
	});

	const handleFilterChange = (option: IFilterStatus) => {
		handleFilter?.(option ?? {});
	};

	const genHeadActions = () => {
		if (!actions?.length) return actions;

		return actions.map((action: IActionsListItem, index: number) => {
			const isDisabled = disabled || action.disabled;
			if (action.render) return action.render(action);
			return (
				<Button
					type="button"
					className={cn([
						"flex-shrink-0 gap-2",
						!isDisabled &&
							"!text-neutral-600 hover:!text-neutral-800 dark:!text-neutral-100 dark:hover:!text-neutral-200",
					])}
					color="transparent"
					{...action}
					key={action.key ?? index}
					onClick={action.onClick}
					disabled={isDisabled}
				>
					{action.icon && (
						<Picto className="!mr-0" icon={action.icon} />
					)}
					<span>{action.label}</span>
				</Button>
			);
		});
	};

	const handleSelect = (items: any, state: boolean) =>
		!selectableItems?.length
			? toast.info(t("global.nothingToSelect"), {
					toastId: "list-nothingToSelect",
			  })
			: onSelected?.(items, state);

	return (
		<div className="flex flex-wrap-reverse items-center justify-between gap-2 p-8 py-3 text-sm font-medium border-t ListHeader bg-neutral-150 dark:bg-neutral-700 dark:border-neutral-900">
			<div className="flex-wrap items-center w-full gap-x-8 gap-y-4 row md:w-auto">
				{selectable && (
					<label
						className={cn([
							"flex flex-shrink-0 w-full cursor-pointer md:w-auto item-center dark:text-neutral-100",
							disabled &&
								"!cursor-not-allowed text-neutral-400  opacity-70",
						])}
					>
						<input
							type="checkbox"
							className="mr-4"
							disabled={disabled}
							checked={!!selected?.length}
							data-indeterminate={
								!!selected?.length &&
								selectableItems?.length !== selected?.length
							}
							onChange={(e) => {
								const isIndeterminate =
									selected?.length &&
									selected?.length !==
										selectableItems?.length;
								handleSelect?.(
									selectableItems,
									isIndeterminate || e.target.checked
								);
							}}
						/>
						<span>{t("global.selectAll")}</span>
					</label>
				)}
				{actions && genHeadActions()}
			</div>
			<div
				className={cn([
					disabled &&
						"opacity-50 cursor-not-allowed pointer-events-none",
				])}
			>
				<SelectLabel
					name="sortType"
					label={`${t("global.sortBy")}:`}
					options={columns
						?.filter((col) => col.sortable !== false)
						.sort((colA, colB) => {
							const colAName = colA.sortLabel ?? colA.name;
							const colBName = colB.sortLabel ?? colB.name;
							return colAName < colBName ? -1 : 1;
						})
						.map((col) => ({
							...col,
							name: col.sortLabel ?? col.name,
							value: col.sortKey ?? col.value,
						}))}
					onChange={(option: IFilterStatus) =>
						handleFilterChange(option)
					}
					value={
						SortBy
							? {
									name: SortBy.sortLabel ?? SortBy.name,
									value: SortBy.sortKey ?? SortBy.value,
							  }
							: null
					}
				/>
			</div>
		</div>
	);
};
