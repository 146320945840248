import * as Yup from "yup";

import {
	validateHhMm,
	validateHourLimit,
	validateHundreds,
	convertHoursToHundreds,
	formatStringToHundreds,
} from "utils";

import { MAX_CONTACT_MESSAGE_LENGTH } from "constants_globals";
import { HOURS_MODE, HUNDREDS_MODE } from "constants_globals/dates";

export interface ITimesheetEditFormValues {}

export const TimesheetEditFormInitialValues = {};

export const TimesheetEditFormValidation = (t: any, mode: string) => {
	const defaultInputTimeValidation = () =>
		Yup.string()
			.nullable()
			.test(
				"max value",
				mode === HUNDREDS_MODE
					? t("timesheets.errors.errorValidateHundreds")
					: t("timesheets.errors.errorValidateHours"),
				(value) => {
					if (!value) return true;
					return validateHourLimit(value, mode);
				}
			)
			.test(
				"validate value",
				t("timesheets.errors.badHourFormat"),
				(value) => {
					if (!value) return true;

					return mode === HUNDREDS_MODE
						? validateHundreds(value)
						: validateHhMm(value);
				}
			);

	return Yup.object().shape({
		tsDays: Yup.array().of(
			Yup.object()
				.shape({
					id: Yup.number(),
					date: Yup.date(),
					isWorkingDay: Yup.bool(),
					isVacationDay: Yup.bool(),
					workedHours: defaultInputTimeValidation(),
					nightHours: defaultInputTimeValidation(),
					notWorkedDay: Yup.bool().nullable(),
				})
				.test(
					"inferior",
					t("timesheets.errors.nightHoursInferior"),
					(value) => {
						let workedHours =
							mode === HUNDREDS_MODE
								? formatStringToHundreds(value.workedHours) || 0
								: convertHoursToHundreds(
										value.workedHours,
										":"
								  ) || 0;
						let nightHours =
							mode === HUNDREDS_MODE
								? formatStringToHundreds(value.nightHours) || 0
								: convertHoursToHundreds(
										value.nightHours,
										":"
								  ) || 0;

						return workedHours >= nightHours;
					}
				)
		),
		tsBonuses: Yup.array().of(
			Yup.object().shape({
				id: Yup.number(),
				type: Yup.string(),
				days: Yup.array().of(
					Yup.object().shape({
						id: Yup.number(),
						date: Yup.date(),
						isWorkingDay: Yup.bool(),
						isVacationDay: Yup.bool(),
						value: Yup.string()
							.nullable()
							.test(
								"max value",
								mode === HUNDREDS_MODE
									? t(
											"timesheets.errors.errorValidateHundreds"
									  )
									: t("timesheets.errors.errorValidateHours"),
								function (value) {
									if (
										["H", "G", "S", "D"].includes(
											this.parent.type
										)
									) {
										return validateHourLimit(
											value || "0",
											mode
										);
									}
									return true;
								}
							)
							.test(
								"validate value",
								t("timesheets.errors.badHourFormat"),
								function (value) {
									if (
										["H", "G", "S", "D"].includes(
											this.parent.type
										)
									) {
										if (value && mode === HUNDREDS_MODE)
											return validateHundreds(value);
										if (value && mode === HOURS_MODE)
											return validateHhMm(value);
									}
									return true;
								}
							),
						type: Yup.string(),
					})
				),
			})
		),
		orderReference: Yup.string(),
		message: Yup.string().max(
			MAX_CONTACT_MESSAGE_LENGTH,
			t("forms.field.max", {
				max: MAX_CONTACT_MESSAGE_LENGTH,
			})
		),
	});
};
